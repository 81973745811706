<template>
    <div class="back-cover page-layout">
        <div class="branding">
            <p class="logo">
                <slot name="logo" />
            </p>
            <p class="url">
                <slot name="url" />
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BackCoverLayout'
};
</script>

<style lang="scss" scoped>
.back-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .branding {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .logo {
        margin: 0;
        height: auto;
        width: 250px;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .url {
        color: $white;
    }
}
</style>